import styled from 'styled-components';

export const Wrapper = styled.div``;

export const Text = styled.h5`
    .pre {
        color: #41403e;
        color: var(--inverse-primary);
        display: block;

        background-color: #41403e;
        background-color: var(--primary-shaded-70);
        border-radius: 3px;
        border: 1px solid #41403e;
        border-color: var(--primary-shaded-50);
        font-size: 0.85rem !important;
        line-height: 1.5;
        overflow-x: auto;
        padding: 1em;
        word-break: break-work;
        word-wrap: break-word;

        font-family: monospace, monospace;
        font-size: 1em;
    }
`;
