import React from 'react';
import { Text, Wrapper } from './Components';

const OImenima = () => {
    return (
        <Wrapper>
            <Text>
                Vlastito Božije ime je <b>Allah</b>. Ono označava Stvoritelja
                svega što postoji. Ono je u Kur&apos;anu spomenuto <b>2698</b>{' '}
                puta. Pored ovog imena, koje se najčešće spominje, postoje i
                druga Allahova lijepa imena - <b>El-esmau-l husna</b>, o čemu
                Kur&apos;an a.š. kaže:
                <br />
                &nbsp;
                <p className="pre">
                    &apos;Allah ima svoja lijepa imena, pa Ga po njima
                    zovite&apos; <br />
                    (El-Earaf, 180).
                </p>
                <br />
                <p className="pre">
                    &apos;On je Allah, Tvorac, Onaj koji iz ničega stvara, Onaj
                    koji svemu daje oblik, On ima najljepša imena.&apos;
                    <br />
                    (Al-Hashr, 24)
                </p>
                <br />
                Muhammed a.s. kaže:
                <p className="pre">
                    &apos;Allah dž.š., ima 99 imena, ko ih zapamti (spozna) ući
                    će u Džennet.&apos; <br />
                    (Buharija i Muslim).
                </p>
                <br />
                O značenju ovog hadisa islamski učenjaci zauzimaju različite
                stavove.
                <br />
                <ul>
                    <li>
                        Jedni smatraju da &apos;dostići&apos; Božija imena znači
                        naučiti ih napamet,
                    </li>
                    <li>
                        drugi misle da se hadis odnosi na one koji shvate njihov
                        smisao i značenje,
                    </li>
                    <li>
                        dok treći smatraju da &apos;dostići&apos; lijepa
                        Allahova imena znači primijeniti njihov smisao i
                        sadržaj, koliko je moguće, u svome životu i ponašanju.
                    </li>
                </ul>
            </Text>
        </Wrapper>
    );
};
export default OImenima;
